import { Typesetter } from "typesettable";

let scale = 1;

// const resize = () => {
// 	scale = Math.min(window.innerWidth / 2448, window.innerHeight / 4032);
// 	document.body.style.transform = `scale(${scale})`;
// };
// window.addEventListener("resize", resize);
// resize();

const typesetter = Typesetter.html(document.getElementById("name"));
window.setName = name => {
	typesetter.write(
		name.trim().toUpperCase(),
		1650 * scale,
		1536 * scale - 160,
		{
			xAlign: "center",
			yAlign: "center"
		}
	);
};

const name = new URL(location.href).searchParams.get("name");

if (name) {
	window.setName(name);
}
